import React, { useState } from 'react';
import axiosInstance from './axiosSetup';

import '../Styles/useraccounts.css';

function SignUp() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the fields is blank
    if (!formData.name || !formData.email || !formData.password) {
      setSuccessMessage(null);
      setErrorMessage('Please fill in all the fields.');
      return;
    }

    // Check if the password meets the specified criteria
    if (
      formData.password.length < 8 ||
      !/[!@#$%^&*()-_=+{};:,<.>]/.test(formData.password) ||
      !/[A-Z]/.test(formData.password)
    ) {
      setSuccessMessage(null);
      setErrorMessage('Password must contain 8 characters, a symbol, and a capital letter.');
      return;
    }

    try {
      // Check if an account with the same email already exists
      const existingUserEmailResponse = await axiosInstance.get(`/ngoconnect/checkuser?email=${formData.email}`);

      if (existingUserEmailResponse.data.exists) {
        setSuccessMessage(null);
        setErrorMessage('User with this email already exists.');
        return;
      }

      // Check if an account with the same fullname already exists
      const existingUserFullnameResponse = await axiosInstance.get(`/ngoconnect/checkname?fullname=${formData.name}`);

      if (existingUserFullnameResponse.data.exists) {
        setSuccessMessage(null);
        setErrorMessage('Username is taken. Please try another one.');
        return;
      }

      // If no existing user found, proceed to create a new account
      const response = await axiosInstance.post('/ngoconnect/addusers', {
        fullname: formData.name,
        email: formData.email,
        password: formData.password,
      });

      setSuccessMessage('Account created successfully. You can sign in.');
      setErrorMessage(null);

      console.log('User added successfully:', response.data);
      // TODO: Handle success, e.g., show a success message, redirect, etc.
    } catch (error) {
      setSuccessMessage(null);
      setErrorMessage('Error adding user. Please try again.');

      console.error('Error adding user:', error.response.data);
      // TODO: Handle error, e.g., show an error message to the user
    }
  };

  return (
    <div className="form-container sign-up-container">
      <form onSubmit={handleSubmit} className='ngo-user-form'>
        <h1>Create Account</h1>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
        />
        <button type="submit">Sign Up</button>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        
      </form>
      <div className='opt'>
          <p>-------- or -------- </p>

        </div>
    </div>
  );
}

export default SignUp;
