// UnauthorizedPage.js
import React from 'react';
import "../Styles/unotheriseduser.css"
import { Link } from 'react-router-dom';

const UnauthorizedPage = () => {
  return (
    <div className='ngo-unothorised-user'>
      <div className='ngo-block-content'>
        <div>
          <h3>Error</h3>
          <h1>401</h1>
        </div>
        <h2>Unauthorized Access</h2>
        <p>Looks like you're trying to sneak into a members-only party. No ticket, no entry!</p>
        <Link to="/" className='ngo-nav'> Home</Link>  | <Link className='ngo-nav' to="/login"> Login</Link>

      </div>
      
    </div>
  );
};

export default UnauthorizedPage;
