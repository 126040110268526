import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPhone, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from './axiosSetup'; // Import the axiosInstance
import '../Styles/navigation.css';

function NavigationMenu() {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    // Fetch the user's full name from the server
    const fetchUserDetails = async () => {

      try {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
          // Access token not found, handle accordingly (e.g., redirect to login)
          return;
        }

        const response = await axiosInstance.get('/ngoconnect/checklogin', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Assuming the server returns the user's full name in the 'fullName' field
        setFullName(response.data.fullName);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const closeMenu = () => {
    setMenuOpen(false);
    setShowDropdown(false); // Close the dropdown when closing the menu
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('is_authenticated');
    navigate('/');
    window.location.reload();
  };

  return (
    <div className={`ngo-navigation-menu ${isMenuOpen ? 'menu-open' : ''}`}>
      {/* Hamburger menu icon */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className='top-bar-container'>
        <div className='ngo-top-bar'>
          <div className='ngo-help'>
            <FontAwesomeIcon icon={faCircleInfo} size="1x" style={{ color: '#d87927' }} />
            <p><Link to = "/help">Help</Link></p>
          </div>
          <div className='contact-help'>
            <FontAwesomeIcon icon={faPhone} size="1x" style={{ color: '#d87927' }} />
            <p>0798306373</p>
          </div>
        </div>
      </div>

      <div className={`ngo-primary-menu ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className={`left-primary-menu ${isMenuOpen ? 'menu-open' : ''}`}>
          <div className='logo-container'>
            <img src="/images/favicon.png" alt="ngoconnect logo" />
          </div>
          <div className='navigation-menu'>
            <nav>
              <ul>
                <li><Link to="/" onClick={closeMenu}>Home</Link> </li>
                <li><Link to="/register" onClick={closeMenu}>Register</Link></li>
                <li><Link to="/ngo-listings" onClick={closeMenu}>All NGOs</Link></li>
                <li><Link to="/about-us" onClick={closeMenu}>About Us</Link></li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={`right-primary-menu ${isMenuOpen ? 'menu-open' : ''}`}>
          {fullName ? (
            <>
              <div className="user-info" onClick={toggleDropdown}>
                <div className="ngo-user-profile">
                  <div className="profile-initial">
                    {fullName && fullName.charAt(0).toUpperCase()} {/* Display the first letter of fullName */}
                  </div>
                      <p>{fullName} <FontAwesomeIcon icon={faChevronDown} /> </p>
                </div>
                {showDropdown && (
                  <div className='dropdown-menu'>
                    <ul>
                      <li><Link to="/">Main Home Page</Link></li>
                      <li><Link to="/ngo-admin">Admin Dashboard</Link></li>
                      <li><Link to="/super-admin">Super Admin Dashboard</Link></li>
                      <li><Link onClick={handleLogout}>Logout</Link></li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Link to="/login" onClick={closeMenu}>Sign in</Link>
              <Link className="ngo-sign-up" to="/signup" onClick={closeMenu}>Sign Up</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavigationMenu;
