import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axiosInstance from "./axiosSetup";
import { useParams } from 'react-router-dom';
import "../Styles/singlengo.css"
import "leaflet/dist/leaflet.css"

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";

import ReviewForm from "./Reviews/ReviewForm"
import NgoReviews from "./Reviews/NGOreviews";
import JobsForNgo from "./JobsForSingNgoPage";

function NgoDetails() {
  const { id: ngoName } = useParams(); // Rename id to ngoName
  const [ngoDetails, setNgoDetails] = useState(null);
  const navigate = useNavigate();
  
  const customIcon = new Icon({
    iconUrl:require("../Location-image/location.png"),
    iconSize: [38,38]
  })

  useEffect(() => {
    console.log("NgoDetails component mounted");
    console.log("Ngo name from useParams:", ngoName);

    const fetchNgoDetails = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
          // Access token not found, handle accordingly (e.g., redirect to login)
          navigate('/login');
          return;
        }

        const response = await axiosInstance.get(`/ngoconnect/ngo/${ngoName}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log("API Response:", response.data);

        setNgoDetails(response.data);
      } catch (error) {
        console.error("Error fetching NGO details:", error);
      }
    };

    fetchNgoDetails();
  }, [ngoName,navigate]);

  if (!ngoDetails) {
    return <div className="loader"></div>;
  }

  return (
    <div>
     <div className="header">
      <div>
          <div className="single-ngo-header"> 
              <h1> {ngoDetails.ngo_name}</h1>
          </div>

        </div>

        <img src={ngoDetails.image_url} alt="organization" />
      </div>

      
      <div className="ngo-singlelisting-page">
        <div className="main-content">
         
          <div className="ngo-focus-area">
            <h3> OUR FOCUS AREAS</h3>
            <p>{ngoDetails.focus_areas}</p>
          </div>

          <div className="ngo-location">
            <h3>OUR HEADQUARTERS LOCATION</h3>
            <p>{ngoDetails.Location}</p>

          </div>

          <div className="ngo-decription">
            <h3>ABOUT US</h3>
            <p>{ngoDetails.description}</p>
            <a href={ngoDetails.website_url} target="_blank" rel="noopener noreferrer" className="btn">
            Visit Website
            </a>
          </div>

          <div className="Ngo-map-location"> 
          <h3>MAP LOCATION</h3>
            <MapContainer center={[1.2921,36.8219 ]} zoom={6}>
              <TileLayer 
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"

              />
              <Marker position={[ngoDetails.latitude, ngoDetails.longitude]} icon={customIcon}>
                <Popup>{ngoDetails.Location}</Popup>
              </Marker>

            </MapContainer>

          </div>
          <div className="ngo-jobs">
          <h3>OPEN POSITIONS</h3>
          <JobsForNgo ngoId={ngoName} />

        </div>

        <div className="ngo-reviews">
            <h3>NGO REVIEWS</h3>
            <NgoReviews ngoId={ngoName} />

          </div>

        </div>

        
        <div className="singel-ngo-sidebar">
            <ReviewForm ngoId={ngoName} />
            
        </div>
        
        
      </div>
    </div>

    
   
  );
}

export default NgoDetails;