import React from 'react';
import '../Styles/Landingpage.css'
import { Link } from 'react-router-dom';

function MainHeader(){
    return(
        <div className='ngo-page-header'>
            

            <div className='ngo-header-text'>
                <div>
                <h1>
                    We-Connect 
                </h1>
                <h3> Communities & NGOS </h3>
                <p >
                NGOconnect is a user - friendly platform that connects users
                with a comprehensive list of NGOs in Kenya, enabling you to explore missions,
                volunteer opportunities, and fundraising campaigns
                </p>

                <div className='ngo-header-link'>
                <Link to='/ngo-listings'>View listed NGOs</Link>
                </div>
            



                </div>
                

            </div>
            
           <div className="ngo-header-image">
                <img src="/images/hero-section.jpg" alt="ngo connect" />
            </div>

        </div>
    );

};

export default MainHeader