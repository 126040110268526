import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';


import LandingPage from './Pages/Landingpage';
import RegisterPage from './Pages/RegisterNgo';
import NavigationMenu from './Components/Navigation';
import Admin from './Pages/Admin';
import ListingsPage from './Pages/Listings';
import NgoAdmin from './Pages/NGodmin';
import Footer from './Components/footer';
import ProtectedRoute from './Components/ProtectedRoute';
import UnauthorizedPage from './Components/Unouthorised';
import NgoDetails from './Components/SingleNGopage';
import PrivacyPolicy from './Components/LegalStuff/Privacypolicy'
import TearmsOfService from './Components/LegalStuff/Termsofservice'
import ApllicationMessage from './Components/RegistrationSeccessfull';
import AboutUs from './Pages/AboutUs';
import ScrollToTop from './Components/ScrollTotop';
import PageNotFound from './Components/PageNotFound';
import RegistrationGuide from './Components/Guide';
import CookieConsentBanner from './Components/CookieConsentBanner';

import Usersignin from './Pages/UserSignin';
import Usersignup from './Pages/Usersignup';


// Reusable Layout Component
const Layout = ({ children }) => {
  return (
    <>
      <NavigationMenu />
      {children}
      <Footer />
    </>
  );
};

function App() {

  return (
    <AnimatePresence>
    <div className="App">
      <Router>
        <ScrollToTop />
          <Routes >
            <Route path='/help' element={<Layout><RegistrationGuide /></Layout>}></Route>
            <Route path="/" element={<Layout><LandingPage /></Layout>} />
            <Route path="/register" element={<Layout><RegisterPage /></Layout>} />
            <Route path="/ngo-listings" element={<Layout><ListingsPage /></Layout>} />
            <Route path="/unauthorized" element={<Layout><UnauthorizedPage /></Layout>} />
            <Route path="/ngo/:id" element={<Layout><NgoDetails /></Layout>} />
            <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
            <Route path="/terms-of-service" element={<Layout><TearmsOfService /></Layout>} />
            <Route path="/success-application" element={<Layout><ApllicationMessage /></Layout>} />
            <Route path='/about-us' element={<Layout><AboutUs /></Layout>} />
            <Route path='/login' element={<Usersignin />} />
            <Route path='/signup' element={<Usersignup />} />        

            <Route
              path="/ngo-admin"
              element={<ProtectedRoute element={<NgoAdmin />} allowedRoles={['admin']} />}
            />
            
            <Route path='/super-admin'
              element={<ProtectedRoute element={<Admin />} allowedRoles={['super_admin']} />}
              />

            <Route path="*" element={<Layout><PageNotFound /></Layout>} />

            
          </Routes>
        
      </Router>
      <CookieConsentBanner />
    </div>
    </AnimatePresence>
  );
}

export default App;
