import React from "react";
import '../Styles/Workingareas.css';

// font awsum icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingHand, faHandHoldingMedical,faShieldHalved } from '@fortawesome/free-solid-svg-icons';

function WorkingAreas() {
    return (
        <div>
            <div className="ngo-registration-header">
                <div>
                    <h1>What we offer</h1>
                </div>
                <div >
                    <p>A platform with verified NGOs where you can:</p>
                </div>
            </div>

            <div className="ngo-work-container">
              
                <div className="ngo-service  ngo-service1">
                <FontAwesomeIcon icon={faShieldHalved}  size="3x" style={{ color: '#d87927' }} />
                    <h3>Explore NGO</h3>
                    <p> Find genuine NGOs you can trust. That are making a difference</p>
                </div>

                <div className="ngo-service">
                    <FontAwesomeIcon icon={faHandHoldingHand} size="3x" style={{ color: '#d87927' }} />
                    <h3>Voluntary Positions</h3>
                    <p> Find genuine NGOs with open volunteer position for you to make a diffrence</p>
                </div>

                <div className="ngo-service">
                    <FontAwesomeIcon icon={faHandHoldingMedical} size="3x" style={{ color: '#d87927' }} />
                    <h3>Making Donations</h3>
                    <p> Find genuine NGOs you can donate to help them make a diffrence</p>
                </div>
            </div>
        </div>
    );
}

export default WorkingAreas;
