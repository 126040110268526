import React from 'react';

import AnimatePage from './AnimatePage';

const RegistrationGuide = () => {
  return (
    <AnimatePage>
    <div style={{ maxWidth: "800px", margin: "auto", padding: "20px" }} className='registration-guide'>
       
      <h2>NGO Registration Form Guide</h2>
      <p>
        Thank you for your interest in registering your NGO with us! Below is a step-by-step guide to help you fill out the registration form accurately.
        <strong> You must be loged in to register an NGO</strong>
      </p>
      <ol>
        <li>
          <strong>NGO Name:</strong><br />
          Provide the official name of your NGO.<br />
          This field is mandatory, so ensure it is filled out.
        </li>
        <li>
          <strong>NGO Email:</strong><br />
          Enter a valid email address associated with your NGO.<br />
          We will use this email to communicate with you, so ensure it is accurate.<br />
          This field is mandatory.
        </li>
        <li>
          <strong>Description:</strong><br />
          Describe your NGO's mission, objectives, and activities briefly.<br />
          Provide relevant information that highlights the work your NGO does.<br />
          This field is mandatory.
        </li>
        <li>
          <strong>Category:</strong><br />
          Specify the category or sector your NGO operates in (e.g., education, healthcare, environment).<br />
          Choose the category that best represents your NGO's focus.<br />
          This field is mandatory.
        </li>
        <li>
          <strong>Location:</strong><br />
          Enter the physical location or address of your NGO's headquarters or main office.<br />
          This field is mandatory.
        </li>
        <li>
          <strong>Latitude and Longitude:</strong><br />
          Provide the geographical coordinates (latitude and longitude) of your NGO's location.<br />
          Ensure these values are accurate, as they help users find your NGO on maps.<br />
          These fields are mandatory and require valid numerical input.
          <p>
            <strong>How to Obtain Latitude and Longitude from Google Maps:</strong>
          </p>
          <ol>
            <li>Open Google Maps (<a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">https://www.google.com/maps</a>).</li>
            <li>Search for your NGO's location using the search bar at the top of the page.</li>
            <li>Once you've found the location, right-click on the exact point where your NGO is located on the map.</li>
            <li>Select "What's here?" from the dropdown menu.</li>
            <li>A small box will appear at the bottom of the screen with the exact latitude and longitude coordinates.</li>
            <li>Copy the latitude and longitude values and paste them into the corresponding fields in the registration form.</li>
          </ol>
          <p>
            Note: If you're using a mobile device, you can press and hold on the map to drop a pin at the location and view its coordinates.
          </p>
        </li>
        <li>
          <strong>Website URL:</strong><br />
          If your NGO has a website, enter its URL here.<br />
          Ensure the URL is correct and starts with "http://" or "https://".<br />
          This field is mandatory.
        </li>
                <li>
        <strong>Image:</strong><br />
        Upload an image representing your NGO.<br />
        - The image should be a JPEG file format.<br />
        - The file size should be less than 1MB.<br />
        This field is mandatory.
        </li>

        <li>
          <strong>Submit:</strong><br />
          Once you have filled out all the required fields, click the "Submit" button.<br />
          Review your information to ensure accuracy before submission.<br />
          After submission, you will be redirected to a confirmation page.
        </li>
        <li>
  <strong>After Submit:</strong><br />
  Once you have successfully submitted the form, you will receive a confirmation email within 24 hours.<br />
  If you do not receive a confirmation email or have any questions regarding your submission, please reach out to our support team for assistance.
</li>

      </ol>
    </div>
    </AnimatePage>
  );
};

export default RegistrationGuide;
