import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/register.css";

function RegisterSection() {
  // Use the useEffect hook to scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="ngo-register-container">
        <div>
          <img src="/images/register.png" alt="register" />
        </div>
        <div className="ngo-register-count">
           <p>OUR GOAL</p>
          <h1>500 + registered NGOs</h1>
        </div>
        <div className="ngo-register-text-content">
          <div>
            <h1>Get NGO enlisted</h1>
            <p>
              The ngo.connect is a user-friendly platform that connects users
              with a comprehensive list of NGOs, enabling you to explore
              missions, volunteer opportunities, and fundraising campaigns
            </p>
            <Link to="/register" className="nota">
              <button>Register NGO</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterSection;
