import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosSetup';
import { useForm } from 'react-hook-form';

import AnimatePage from '../AnimatePage'

const UpdateNgoForm = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [ngoDetails, setNgoDetails] = useState({
    ngo_name: '',
    email: '',
    description: '',
    image_url: '',
    website_url: '',
    focus_areas: '',
    Location: '',
    latitude: '',
    longitude: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const getUserToken = () => {
    return localStorage.getItem('access_token');
  };

  useEffect(() => {
    const fetchNgoDetails = async () => {
      try {
        const token = getUserToken();
        const response = await axiosInstance.get('/ngoconnect/my-ngo', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setNgoDetails(response.data);

        // Prefill form fields with the fetched data
        Object.keys(response.data).forEach((field) => {
          setValue(field, response.data[field]);
        });
      } catch (error) {
        console.error('Error fetching NGO details:', error);
      }
    };

    fetchNgoDetails();
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const token = getUserToken();

      // Send the form data to your Flask API without image upload
      const response = await axiosInstance.put(
        '/ngoconnect/my-ngo',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSuccessMessage(response.data.message);
      setErrorMessage(''); // Reset error message

      console.log(response.data.message);
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage(''); // Reset success message
      } else {
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <AnimatePage >
    <div className="ngo-update-form">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}

      <div>
        <h2>Update NGO details</h2>
      <p>On th form below type the change you wish to make and press the update button to make the required change</p>

        </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          NGO Name:
          <input
            type="text"
            {...register('ngo_name')}
          />
        </label>
        <label>
          Email:
          <input
            type='text'
            {...register('email')}
          />
        </label>

        <label className='column-in-span-2'>
          Description:
          <textarea
            {...register('description')}
          />
        </label>

        <label className='column-in-span-2'>
          Website URL:
          <input
            type="text"
            {...register('website_url')}
          />
        </label>

        <label>
          Focus Areas:
          <input
            type="text"
            {...register('focus_areas')}
          />
        </label>

        <label>
          Location:
          <input
            type="text"
            {...register('Location')}
          />
        </label>

        <label>
          Latitude:
          <input
            type="number"
            step="any"
            {...register('latitude')}
          />
        </label>

        <label>
          Longitude:
          <input
            type="number"
            step="any"
            {...register('longitude')}
          />
        </label>

        <label>
          Created At:
          <input
            type="text"
            disabled
            value={ngoDetails.created_at}
          />
        </label>
        <button type="submit">Update NGO Details</button>

        <div className='column-in-span-2'>
          <img src={ngoDetails.image_url} alt="" />
        </div>
      </form>
    </div>
    </AnimatePage>
  );
};

export default UpdateNgoForm;
