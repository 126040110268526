import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosSetup'; // Import the axiosInstance
import { Link } from 'react-router-dom';
import '../Styles/SuperAdmin.css';

const ManageListing = () => {
  const [ngos, setNgos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNgos, setFilteredNgos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNgoId, setSelectedNgoId] = useState(null);
  const [newApprovalStatus, setNewApprovalStatus] = useState('');
  const [filterByApproval, setFilterByApproval] = useState('all'); // 'all', 'approved', 'not_approved'
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchNGOs = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const response = await axiosInstance.get('/ngoconnect/ngolist', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setNgos(response.data.All_NGOs);
        setFilteredNgos(response.data.All_NGOs);
      } catch (error) {
        console.error('Error fetching NGOs:', error);
      }
    };

    fetchNGOs();
  }, []);

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredNgos = ngos.filter((ngo) =>
      ngo.ngo_name.toLowerCase().includes(searchTerm) ||
      ngo.focus_areas.toLowerCase().includes(searchTerm)
    );

    // Apply additional filter based on approval status
    const approvalFilteredNgos = applyApprovalFilter(filteredNgos);
    setFilteredNgos(approvalFilteredNgos);
  };

  const handleFilterByApproval = (event) => {
    const value = event.target.value;
    setFilterByApproval(value);

    // Apply additional filter based on approval status
    const approvalFilteredNgos = applyApprovalFilter(ngos);
    setFilteredNgos(approvalFilteredNgos);
  };

  const applyApprovalFilter = (ngosToFilter) => {
    switch (filterByApproval) {
      case 'approved':
        return ngosToFilter.filter((ngo) => ngo.approved);
      case 'not_approved':
        return ngosToFilter.filter((ngo) => !ngo.approved);
      default:
        return ngosToFilter;
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNgos.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle edit approval status
  const handleEditApprovalStatus = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      await axiosInstance.put(`/ngoconnect/apply/${selectedNgoId}`, { approved: newApprovalStatus }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Update the NGO's approval status in the state
      setNgos((prevNgos) =>
        prevNgos.map((ngo) =>
          ngo.id === selectedNgoId ? { ...ngo, approved: newApprovalStatus } : ngo
        )
      );

      // Reset the selected NGO and approval status
      setSelectedNgoId(null);
      setNewApprovalStatus('');
    } catch (error) {
      console.error('Error updating approval status:', error);
    }
  };

  return (
    <div className="applications-container">
      <h2>NGO List</h2>
      <div className='ngo-users-search'>
        <input
          type="text"
          placeholder="Search NGOs..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <select onChange={handleFilterByApproval} value={filterByApproval}>
          <option value="all">All NGOs</option>
          <option value="approved">Not Approved NGOs</option>
          <option value="not_approved">Approved</option>
        </select>
      </div>

      <table className="ngo-table">
        <thead>
          <tr>
            <th>ID</th> 
            <th>Image</th>
            <th>Name</th>
            <th>User ID</th> 
            <th>Focus Areas</th>
            <th>Website URL</th>
            <th>Approved</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((ngo) => (
            <tr key={ngo.id}>
              <td>{ngo.id}</td>
              <td><img src={ngo.image_url} alt="NGO Logo" className="ngo-logo" style={{ width: '50px', height: '50px' }}/></td>
              <td>
                <Link to={`/ngo/${ngo.id}`} className="ngo-name-link">
                  {ngo.ngo_name}
                </Link>
              </td>
              <td>{ngo.user_id}</td>
              <td>{ngo.focus_areas}</td>
              <td>
                <a href={ngo.website_url} target="_blank" rel="noopener noreferrer" className="ngo-website-link">
                  Visit Website
                </a>
              </td>
              <td>
                {selectedNgoId === ngo.id ? (
                  <select
                    value={newApprovalStatus}
                    onChange={(e) => setNewApprovalStatus(e.target.value === "true")}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                ) : (
                  ngo.approved ? 'Yes' : 'No'
                )}
              </td>
              <td>
                {selectedNgoId === ngo.id ? (
                  <button onClick={handleEditApprovalStatus}>Update</button>
                ) : (
                  <button onClick={() => setSelectedNgoId(ngo.id)}>Edit approval</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredNgos.length / itemsPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ManageListing;
