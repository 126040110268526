// AverageRating.js

import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosSetup";

function AverageRating({ ngoId }) {
  const [averageRating, setAverageRating] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAverageRating = async () => {
      try {
        const response = await axiosInstance.get(`/ngoconnect/avg-rating/${ngoId}`); // Replace with your actual endpoint
        setAverageRating(response.data["Average Rating for NGO"]);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAverageRating();
  }, [ngoId]);

  return (
    <div className="ngo-admin-avragerating">
      <h3>Average Rating </h3>
      {isLoading && <p>Loading average rating...</p>}
      {error && <p>Error: {error}</p>}
      {!isLoading && averageRating !== null && (
        <h1>{`${averageRating.toFixed(2)}`}</h1>
      )}
    </div>
  );
}

export default AverageRating;
