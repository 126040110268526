import React from "react";
import NgoListings from "../Components/Ngolistings";
import { Suspense } from 'react'; // used for lazy loading
import ListingSidebar from "../Components/NgolistingSidebar";
import { Helmet } from "react-helmet";


function ListingsPage(){
    const featuredImage = '/images/favicon.png'

    return(
        <div>
            <Helmet>
                <title>List of NGOs</title>
                <meta name="description" content="See the list of NGOs listed on our platform to find the NGO that matches your needs" />
                <meta property="og:title" content="Listed NGOs " />
                <meta property="og:description" content="See the list of NGOs listed on our platform to find the NGO that matches your needs" />
                <meta property="og:image" content={featuredImage} />
            </Helmet>


            <div className="ngo-listing-header">
                <div>
                <h1>NGO-Listings</h1>
                </div>
                <div>
                  {/* Empty div */}
                </div>
           
            </div>

            <div className="ngo-listing-page">
            
            <div>
            <Suspense fallback={<p>Loading</p>}>
                <NgoListings />
            </Suspense>
            
            </div>
            <div className="ngo-list-sidebar">
                <ListingSidebar />
            </div>
        </div>

        </div>
        
    )
}
export default ListingsPage;