import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosSetup';  // Import the axiosInstance
import '../Styles/SuperAdmin.css';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [updatedUserRole, setUpdatedUserRole] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRoleFilter, setSelectedRoleFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const response = await axiosInstance.get('/ngoconnect/users', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = response.data;
        setUsers(data.Users);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUsers();
  }, []);

  // const handleSoftDelete = async (userId) => {
  //   try {
  //     const accessToken = localStorage.getItem('access_token');
  //     await axiosInstance.delete(`/ngoconnect/users/${userId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });

  //     // Remove the deleted user from the state
  //     setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
  //   } catch (error) {
  //     console.error('Error deleting user:', error);
  //   }
  // };

  const handleUpdateRole = async () => {
    try {
      // Check if the updated role is one of the allowed values
      if (!['normal', 'super_admin', 'admin'].includes(updatedUserRole)) {
        console.error('Invalid role:', updatedUserRole);
        return;
      }

      const accessToken = localStorage.getItem('access_token');
      await axiosInstance.patch(`/ngoconnect/users/${selectedUserId}`, { role: updatedUserRole }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Update the user's role in the state
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUserId ? { ...user, role: updatedUserRole } : user
        )
      );

      // Reset the selected user and role
      setSelectedUserId(null);
      setUpdatedUserRole('');
    } catch (error) {
      console.error('Error updating user role:', error);
    }
  };

  // Filter users based on search term and selected role
  const filteredUsers = users.filter((user) => {
    const searchTermMatch =
      user.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase());

    const roleFilterMatch = selectedRoleFilter
      ? user.role === selectedRoleFilter
      : true;

    return searchTermMatch && roleFilterMatch;
  });

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {error && <p>Error: {error}</p>}
      <h2> Users List </h2>
      <div className='ngo-users-search'>
        {/* Search input */}
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Role filter dropdown */}
        <select
          value={selectedRoleFilter}
          onChange={(e) => setSelectedRoleFilter(e.target.value)}
        >
          <option value="">All Roles</option>
          <option value="super_admin">Super Admin</option>
          <option value="admin">Admin</option>
          <option value="normal">Normal</option>
        </select>
      </div>

      <div className='ngo-user-table'>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.fullname}</td>
                <td>{user.email}</td>
                <td>
                  {selectedUserId === user.id ? (
                    <select
                      value={updatedUserRole}
                      onChange={(e) => setUpdatedUserRole(e.target.value)}
                    >
                      <option value="super_admin">Super Admin</option>
                      <option value="admin">Admin</option>
                      <option value="normal">Normal</option>
                    </select>
                  ) : (
                    user.role
                  )}
                </td>
                <td>
                  {selectedUserId === user.id ? (
                    <button onClick={handleUpdateRole}>Update Role</button>
                  ) : (
                    <button onClick={() => setSelectedUserId(user.id)}>
                      Edit Role
                    </button>
                  )}
                  {/* <button onClick={() => handleSoftDelete(user.id)}>
                    Soft Delete
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>

      {/* Pagination */}
      <div className='pagination'>
        {Array.from({ length: Math.ceil(filteredUsers.length / itemsPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UserList;
