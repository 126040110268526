import React, { useState } from "react";
import axiosInstance from "../axiosSetup";

const CreateJobForm = () => {
  const initialJobData = {
    position_title: "",
    description: "",
    qualifications: "",
    application_link: "",
    position_type: "",
  };

  const [jobData, setJobData] = useState(initialJobData);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validate required fields
      if (
        jobData.position_title === "" ||
        jobData.description === "" ||
        jobData.qualifications === "" ||
        jobData.position_type === ""
      ) {
        setErrorMessage("All fields except Application Link are required");
        return;
      }

      // Assuming your Flask API endpoint is /create-job
      const response = await axiosInstance.post("/ngoconnect/addjob", jobData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setSuccessMessage("Job created successfully");
      setErrorMessage(null);
      console.log(response.data);
      // Reset inputs back to blank
      setJobData(initialJobData);
      // You can handle success response here, e.g., redirect to another page.
    } catch (error) {
      console.error("Error creating job:", error.response?.data || error.message);
      // You can handle error here, e.g., show an error message to the user.
      setSuccessMessage(null);
      setErrorMessage("Error creating job. Please try again.");
    }
  };

  return (
    <div className="ngo-job-post">
      <form onSubmit={handleSubmit}>
        <h2>Create A job Listing</h2>
        <p>Fill in the form below to post a job listing for your NGO</p>
        
          <input
            type="text"
            placeholder="Position Title:"
            name="position_title"
            value={jobData.position_title}
            onChange={handleChange}
            required
         
          />
  
          <textarea
            name="description"
            placeholder=" Description:"
            value={jobData.description}
            onChange={handleChange}
            required
          />
      
          <textarea
            name="qualifications"
            placeholder="Qualifications:"
            value={jobData.qualifications}
            onChange={handleChange}
            required
          />
         
          <input
            type="text"
            placeholder="Application Link"
            name="application_link"
            value={jobData.application_link}
            onChange={handleChange}
          />
  
          Position Type:
          <select
            name="position_type"
            value={jobData.position_type}
            onChange={handleChange}
            required
          >
            <option value="">Select Position Type</option>
            <option value="Volunteer">Volunteer</option>
            <option value="Contract">Contract</option>
            <option value="Full Time">Full Time</option>
          </select>
        

        <button type="submit">Create Job</button>
      </form>

      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default CreateJobForm;
