import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosSetup';

const ratingEmojis = ["😠", "😟", "😐", "😊", "😃"];
const reviewsPerPage = 5;

function NgoReviews({ ngoId }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Fetch reviews for the specific NGO using Axios or your 
    const fetchReviews = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
          // Handle the case where the access token is not available (e.g., redirect to login)
          console.error('Access token not found.');
          return;
        }

        const response = await axiosInstance.get(`/ngoconnect/reviews/${ngoId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setReviews(response.data['Reviews for NGO']);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [ngoId]); // Include ngoId in the dependency array to re-run the effect when ngoId changes

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <p>Loading reviews...</p>;
  }

  if (reviews.length === 0) {
    return <p>No reviews available for this NGO.</p>;
  }

  return (
    <div className='ngo-reviews-container'>
      <ul>
        {currentReviews.map((review) => (
          <li key={review.id}>
            <br />
            Rating: {ratingEmojis[review.rating - 1]} {/* Use the rating to index the corresponding emoji */}
            <br />
            <p>{review.text_review}</p>
            <p className='user-review'>{review.user_full_name} </p>
          </li>
        ))}
      </ul>
      
      {/* Pagination */}
      <div className='pagination'>
        {Array.from({ length: Math.ceil(reviews.length / reviewsPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default NgoReviews;
