import React from "react";
import "../Styles/Aboutus.css"
import AnimatePage from "../Components/AnimatePage";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Ourmission from "../Components/OurGoals";

function AboutUs (){
    const featuredImage = '/images/favicon.png'

    return(
        <AnimatePage >
            <Helmet>
            <title>About NGO-CONNECT</title>
            <meta name="description" content="A user-friendly platform that connects user with a comprehensive list of NGOs" />
            <meta property="og:title" content="About NGO-CONNECT" />
            <meta property="og:description" content="A user-friendly platform that connects user with a comprehensive list of NGOs" />
            <meta property="og:image" content={featuredImage} />
          </Helmet>

        
        <div className="ngo-about-container">
            <div className="ngo-about-header">
                <div className="ngo-about-header-left">
                    <h1>About US</h1>
                    <p>
                    At NGOconnect, our mission is to foster a world where social impact is accessible to all. 
                    Founded by dedicated team of visionaries, who recognized a critical gap in the nonprofit
                    sector: the absence of a centralized platform that seamlessly connects individuals with NGOs, volunteer opportunities, and fundraising campaigns.
                    </p>

                </div>
                <div className="ngo-about-header-Right">
                    <img src="/images/aboutus.jpg"  alt="ngo-admin-header"/>

                </div>

            </div>
            <div className="ngo-about-solution">
                <div className="quote">
                    <p> ❝ </p>
                </div>
                
                    <p>
                    Whether you're passionate about environmental 
                    conservation, education, or healthcare, NGOconnect connects you with organizations aligned with your values and interests.
                    </p>
                <div className="quote">
                    <p> ❞</p>
                </div>
                
            </div>
            <div className="ngo-mission-cards">
                <Ourmission />
            </div>
            
            
            <div className="join-us">
                <div className="join-us-interior">
                <h1>Join us on our mission</h1>
                <p>Join us on our mission to create a more inclusive and compassionate world.
                    Whether you're an NGO seeking greater visibility, a volunteer eager to make a difference, 
                    or a donor looking to support meaningful causes. Together, we can harness the power of collective action to drive lasting change.
                </p>
                <div className="call-to-action">
                    <Link to="/register">
                    <button>
                        Register NGO
                    </button>
                    </Link>
                    <Link to="/login">
                    <button>
                        Sign Up
                    </button>

                    </Link>

                    

                </div>
                <div className="contacts">


                </div>
                </div>
                <div className="img-side">
                    <img src="/images/sidengo.jpg" alt="side" />
                </div>


            </div>


        </div>
        </AnimatePage>
    )

}
export default AboutUs