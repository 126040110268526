import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosSetup';

const ApplicationCount = () => {
  const [applicationsCount, setApplicationsCount] = useState(null);

  useEffect(() => {
    const fetchApplicationsCount = async () => {
      try {
        const response = await axiosInstance.get('/ngoconnect/numberofapplications'); // Replace with your actual API endpoint
        setApplicationsCount(response.data.applications_count);
      } catch (error) {
        console.error('Error fetching applications count:', error);
      }
    };

    fetchApplicationsCount();
  }, []);

  return (
    <div>
      {applicationsCount !== null ? (
        <p>{applicationsCount}</p>
      ) : (
        <p>loading..</p>
      )}
    </div>
  );
};

export default ApplicationCount;
