import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosSetup';

const UsersCount = () => {
  const [usersCount, setUsersCount] = useState(null);

  useEffect(() => {
    const fetchUsersCount = async () => {
      try {
        const response = await axiosInstance.get('/ngoconnect/countusers'); // Replace with your actual API endpoint
        setUsersCount(response.data['total users']);
      } catch (error) {
        console.error('Error fetching users count:', error);
      }
    };

    fetchUsersCount();
  }, []);

  return (
    <div>
      {usersCount !== null ? (
        <p>{usersCount}</p>
      ) : (
        <p>loading..</p>
      )}
    </div>
  );
};

export default UsersCount;
