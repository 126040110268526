import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosSetup"; // Import the axiosInstance

function VisitCount({ ngoId }) {
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    const fetchVisitCount = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
          // Access token not found, handle accordingly
          return;
        }

        const response = await axiosInstance.get(`/ngoconnect/ngo/${ngoId}/visit`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setVisitCount(response.data.visit_count);
      } catch (error) {
        console.error("Error fetching visit count:", error);
      }
    };

    fetchVisitCount();
  }, [ngoId]);

  return (
    <div className="ngo-admin-avragerating">
      <h3>Visit Count</h3>
      <h1>{visitCount}</h1>
    </div>
  );
}

export default VisitCount;
