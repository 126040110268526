import React from "react";
import '../Styles/register.css';
import { Link } from "react-router-dom";

import RegisterForm from "../Components/Registrationform";
import FormSideBar from "../Components/RegFormSidebar";
import AnimatePage from "../Components/AnimatePage";

function RegisterPage(){
    return(

        <AnimatePage>
            <div> 
        <div className="ngo-registration-header">
            <div>
            <h1>NGO registration</h1>
            <div className="p-guide"> 
            <p>Read this <span ><Link className= "reg-col"to = "/help"> Guide</Link></span> to help you enlist an NGO</p>
            </div>
          
            </div>
            <div>
                <p> Fill in the form below to get enlisted to the sight</p>
            </div>
           
        </div>
        <div className="ngo-registration-page">
            
            <div>
                <RegisterForm />
            </div>
            <div>
                <FormSideBar />
            </div>
    
        </div>

       </div>

        </AnimatePage>
       
        
    );

}

export default RegisterPage;