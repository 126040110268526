import React  from 'react';
// For meta data 
import { Helmet } from 'react-helmet';



import '../Styles/Landingpage.css';
import WorkingAreas from '../Components/Workingareas';
import MainHeader from '../Components/MainHeader';
import RegisterSection from '../Components/Registerngo';
import AnimatePage from '../Components/AnimatePage';

function LandingPage() {
  const featuredImage = '/images/favicon.png'
 

  return (
    <AnimatePage>
      <div>
      <Helmet>
        <title>NGO-CONNECT</title>
        <meta name="description" content="A user-friendly platform that connects user with a comprehensive list of NGOs in Kenya" />
        <meta property="og:title" content="NGO-CONNECT " />
        <meta property="og:description" content="A user-friendly platform that connects user with a comprehensive list of NGOs in Kenya" />
        <meta property="og:image" content={featuredImage} />
      </Helmet>

      </div>

      <div className="ngo-landing-page">
      <div className='ngo-landing-container'>
        <div>
          <MainHeader />
        </div>
        <div className='working-areas'>
          <WorkingAreas />
        </div>
        <div>
          <RegisterSection />
        </div>
      </div>
    </div>


    </AnimatePage>
    
  );
}

export default LandingPage;
