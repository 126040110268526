import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosSetup';
import { Link } from 'react-router-dom';
import '../Styles/SuperAdmin.css';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';

const Applications = () => {
  
  const [ngos, setNgos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNgos, setFilteredNgos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showAlert, setShowAlert] = useState(false); // State for managing the alert

  useEffect(() => {
    const fetchNGOs = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const response = await axiosInstance.get('/ngoconnect/application', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setNgos(response.data.All_NGOs);
        setFilteredNgos(response.data.All_NGOs);
      } catch (error) {
        console.error('Error fetching NGOs:', error);
      }
    };

    fetchNGOs();
  }, []);

  const handleDelete = async (ngoId) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      await axiosInstance.delete(`/ngoconnect/ngo/${ngoId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Remove the deleted user from the state
      setNgos((prevNGO) => prevNGO.filter((ngo) => ngo.id !== ngoId));

      // Show the alert
      setShowAlert(true);

      // Hide the alert after a certain duration (e.g., 3 seconds)
      setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Adjust the duration as needed
    } catch (error) {
      console.error('Error deleting ngo:', error);
    }
  };

  const handleToggleApproval = async (ngoId) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      await axiosInstance.put(`/ngoconnect/apply/${ngoId}`, { approved: true }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setNgos((prevNgos) =>
        prevNgos.map((ngo) =>
          ngo.id === ngoId ? { ...ngo, approved: true } : ngo
        )
      );
    } catch (error) {
      console.error('Error setting approval to true:', error);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredNgos = ngos.filter((ngo) =>
      ngo.ngo_name.toLowerCase().includes(searchTerm) ||
      ngo.focus_areas.toLowerCase().includes(searchTerm)
    );

    setFilteredNgos(filteredNgos);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNgos.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="applications-container">
      {/* Alert */}
      {showAlert && (
        <Alert variant="filled" severity="success">
          NGO deleted successfully.
        </Alert>
      )}

      <h2>NGO Applications</h2>
      <input
        type="text"
        placeholder="Search NGOs..."
        value={searchTerm}
        onChange={handleSearch}
      />

      <table className="ngo-table">
        <thead>
          <tr>
            <th>ID</th> 
            <th>Image</th>
            <th>Name</th>
            <th>User ID</th> 
            <th>Focus Areas</th>
            <th>Website URL</th>
            <th>Approved</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((ngo) => (
            <tr key={ngo.id}>
              <td>{ngo.id}</td>
              <td><img src={ngo.image_url} alt="NGO Logo" className="ngo-logo" style={{ width: '50px', height: '50px' }}/></td>
              <td>
                <Link to={`/ngo/${ngo.id}`} className="ngo-name-link">
                  {ngo.ngo_name}
                </Link>
              </td>
              <td>{ngo.user_id}</td>
              <td>{ngo.focus_areas}</td>
              <td>
                <a href={ngo.website_url} target="_blank" rel="noopener noreferrer" className="ngo-website-link">
                  Visit Website
                </a>
              </td>
              <td>{ngo.approved ? 'Yes' : 'No'}</td>
              <td>
                <button onClick={() => handleToggleApproval(ngo.id)} disabled={ngo.approved}>
                  Approve
                </button>
              </td>
              <td>
                <button className="delete-button" onClick={() => handleDelete(ngo.id)}>
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredNgos.length / itemsPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Applications;
