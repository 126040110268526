import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosSetup";
import ReactPaginate from "react-paginate";


function NgoReviews({ ngoId }) {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 5;

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axiosInstance.get(`/ngoconnect/reviews/${ngoId}`); // Replace with your actual endpoint
        setReviews(response.data["Reviews for NGO"]);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, [ngoId]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * reviewsPerPage;
  const currentReviews = reviews.slice(offset, offset + reviewsPerPage);

  return (
    <div>
      <h1>My Reviews </h1>
      {isLoading && <p>Loading reviews...</p>}
      {error && <p>Error: {error}</p>}
      {!isLoading && reviews.length === 0 && <p>No reviews available.</p>}
      {!isLoading && reviews.length > 0 && (
        <>
          <table>
            <thead>
              <tr>
                <th>Rating</th>
                <th>Review</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              {currentReviews.map((review) => (
                <tr key={review.id}>
                  <td>{review.rating}</td>
                  <td>{review.text_review}</td>
                  <td>{review.user_full_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(reviews.length / reviewsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"custom-pagination-container"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default NgoReviews;
