import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const ImageUploadForm = () => {
  const { handleSubmit, setValue, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onSubmit = async (data) => {
    try {
      // Upload image to Cloudinary
      const formData = new FormData();
      formData.append('file', data.image);
      formData.append('upload_preset', 'ngo-connect');

      const cloudinaryResponse = await axios.post(
        'https://api.cloudinary.com/v1_1/dmrtbhnzu/image/upload',
        formData
      );

      const imageUrl = cloudinaryResponse.data.secure_url;

      const accessToken = localStorage.getItem('access_token');

      // Send only the image URL to your Flask API for updating
      const response = await axios.put(
        '/ngoconnect/my-ngo',
        {
          image_url: imageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSuccessMessage('Image uploaded successfully');
      setErrorMessage(''); // Reset error message

      console.log(response.data.message);
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage(''); // Reset success message
      } else {
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <div className="image-upload-form">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>Update Image Here</p>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setValue('image', e.target.files[0])}
        />
        {errors.image && <span className="error-message">Image is required</span>}

        <button type="submit">Update Image</button>
      </form>
    </div>
  );
};

export default ImageUploadForm;
