import React from "react";
import '../Styles/register.css'

function FormSideBar(){
    return(
        <div className="ngo-registration-formsidebar">
      
            <div className="ngo-leftside-form">
                {/* <div className="absolute-image" >
                    <h2> Register Here </h2>
                </div> */}
                <img  src="/images/hero-section.jpg" alt="connect with us"/>
            </div>
            <div>
                <p>
                Register your NGO and be part of a global 
                community committed to social change.
                </p>
            </div>
            <div>
                <h3>We are Aiming to register over 500 verified  NGOs  </h3>
            </div>

        </div> 
    );

}

export default FormSideBar;