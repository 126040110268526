import React from "react";
import { Link } from "react-router-dom";

function ListingSidebar(){
    return(
        <div>
            <div>
            <h3>Registration Open</h3>
            
        </div>
        <div className="ngo-advert">
            <Link to ='/register'>
            <img src="/images/image2.jpg" alt="advert images"/>
            </Link>
        </div>

        </div>
        

    );
}

export default ListingSidebar;