import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const GoogleLoginComponent = () => {
  const navigate = useNavigate();

  const loginUser = (email) => {
    const userLoginEndpoint = '/ngoconnect/login'; // Your login endpoint URL
    
    // Prepare the login data object
    const loginData = {
      email: email,
      password: 'x!T3Kv#r9L@wZ6%' // Set the default password here
    };

    // Make a POST request to the login endpoint
    axios.post(userLoginEndpoint, loginData)
      .then(response => {
        const userData = response.data;

        // Store the tokens in local storage
        localStorage.setItem('refresh_token', userData.refresh_token);
        localStorage.setItem('access_token', userData.access_token);
        localStorage.setItem('user_role', userData.role);

        switch (userData.role) {
            case 'super_admin':
              navigate('/super-admin');
              break;
            case 'admin':
              navigate('/ngo-admin');
              break;
            case 'normal':
              navigate('/ngo-listings');
              break;
            default:
              navigate('/');
          }

        
      })
      .catch(error => {
        console.error('Login failed:', error);
      });
  };

  return (
    <GoogleLogin
      onSuccess={credentialResponse => {
        const credentialResponseDecode = jwtDecode(credentialResponse.credential);
        const email = credentialResponseDecode.email;

        // Call loginUser with the user's email
        loginUser(email);
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  );
};

export default GoogleLoginComponent;
