import axios from 'axios';

const axiosInstance = axios.create();
let isRefreshing = false;
let refreshQueue = [];

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          // Fetch the new access token from the updated server-side endpoint
          const response = await axios.post('/ngoconnect/newtoken', null, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`
            }
          });

          const newAccessToken = response.data.access_token;

          // Update the localStorage with the new access token
          localStorage.setItem('access_token', newAccessToken);

          // Update the original request with the new access token
          originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;

          // Retry the original request
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Handle refresh token failure (e.g., clear tokens and redirect to login page)
          console.error('Refresh token failed:', refreshError);
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          
          // Redirect to "/accounts"
          window.location.href = '/user-accounts';
          
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
          // Process any queued requests
          refreshQueue.forEach((retry) => retry());
          refreshQueue = [];
        }
      } else {
        // If a refresh is already in progress, queue the original request
        return new Promise((resolve) => {
          refreshQueue.push(() => resolve(axiosInstance(originalRequest)));
        });
      }
    }

    // Return error if it's not related to token expiration
    return Promise.reject(error);
  }
);

export default axiosInstance;
