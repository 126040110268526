import React, { useState } from "react";
import axiosInstance from "../axiosSetup";

const ratingEmojis = ["😠", "😟", "😐", "😊", "😃"];

function ReviewForm({ ngoId }) {
  const [rating, setRating] = useState(0);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleRatingHover = (index) => {
    setHoveredRating(index + 1);
  };

  const handleRatingLeave = () => {
    setHoveredRating(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (rating === 0) {
      setErrorMessage("Please select a rating emoji.");
      return;
    }

    if (text.trim() === "") {
      setErrorMessage("Please fill in the review text.");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);

      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        // Access token not found, handle accordingly (e.g., redirect to login)
        return;
      }

      const response = await axiosInstance.post(
        `/ngoconnect/ngo/${ngoId}/reviews`,
        { rating, text },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Review created successfully:", response.data);

      // Display success message
      setSuccessMessage("Review created successfully");

      // Reset form fields
      setRating(0);
      setText("");
    } catch (error) {
      console.error("Error creating review:", error);
      setSuccessMessage(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="ngo-review-field">
      <h3>Review NGO</h3>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <div className="emoji-container">
        <label>Rating:</label>
        {ratingEmojis.map((emoji, index) => (
          <div
            key={index}
            className="emoji-wrapper"
            onMouseEnter={() => handleRatingHover(index)}
            onMouseLeave={handleRatingLeave}
          >
            <button
            className="rating-emoji"
              onClick={() => setRating(index + 1)}
              style={{
                fontSize: "20px",
                margin: "4px",
                background: index + 1 === rating ? "lightblue" : "none",
                border:
                  index + 1 === rating
                    ? "2px solid #213343"
                    : "1px solid #d87927",
                borderRadius: "20%",
                cursor: "pointer",
              }}
            >
              {emoji}
            </button>
            {hoveredRating === index + 1 && (
              <span className="tooltip">{index + 1}</span>
            )}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="ngo-review-form">
        <textarea
          className="review-text"
          placeholder="Write review for NGO here"
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit Review"}
        </button>
      </form>

      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
    </div>
  );
}

export default ReviewForm;
