import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import "../Styles/Goals.css"

function Ourmission() {
  useEffect(() => {
    const firstCard = document.querySelector('.first-card');
    const secondCard = document.querySelector('.second-card');
    const thirdCard = document.querySelector('.third-card');

    const tl = gsap.timeline({ paused: true });

    // Store initial rotations in localStorage
    const firstCardRotation = localStorage.getItem('firstCardRotation') || -20;
    const secondCardRotation = localStorage.getItem('secondCardRotation') || 5;
    const thirdCardRotation = localStorage.getItem('thirdCardRotation') || 20;

    gsap.set(firstCard, { rotation: firstCardRotation });
    gsap.set(secondCard, { rotation: secondCardRotation });
    gsap.set(thirdCard, { rotation: thirdCardRotation });

    // Animation for hovering the first card
    firstCard.addEventListener('mouseenter', () => {
      tl.clear(); // Clear previous animations
      tl.to(firstCard, { rotation: 0, y: 0, duration: 0.6, ease: "power2.out" })
        .to([secondCard, thirdCard], { x: '+=20', duration: 0.6, ease: "power2.out" })
        .play();
    });

    // Animation for hovering the second card
    secondCard.addEventListener('mouseenter', () => {
      tl.clear(); // Clear previous animations
      tl.to(secondCard, { rotation: 0, y: 0, duration: 0.6, ease: "power2.out" })
        .to(firstCard, { x: '-20', duration: 0.6, ease: "power2.out" })
        .to(thirdCard, { x: '+20', duration: 0.6, ease: "power2.out" })
        .play();
    });

    // Animation for hovering the third card
    thirdCard.addEventListener('mouseenter', () => {
      tl.clear(); // Clear previous animations
      tl.to(thirdCard, { rotation: 0, y: 0, duration: 0.6, ease: "power2.out" })
        .to([firstCard, secondCard], { x: '-20', duration: 0.6, ease: "power2.out" })
        .play();
    });

    // Reverse animations instantly on mouse leave
    [firstCard, secondCard, thirdCard].forEach(card => {
      card.addEventListener('mouseleave', () => {
        tl.reverse();
      });
    });

  }, []);

  return (
    <div className="container">
      <div className="first-card">
        <h4>HO WE EMPOWER CHANGE</h4>
        <p>we believe that every action, no matter how small, has the potential to create a ripple effect of positive change.</p>
        
        <img src="/images/aboutus.jpg" alt="imgsource" />
      </div>

      <div className="second-card">
        <h4>OUR VISION </h4>
        <p>we envision a future where anyone, regardless of their background or location, can easily discover and support causes they are passionate about.</p>
        
        <img src="/images/aboutus.jpg" alt="imgsource" />
      </div>

      <div className="third-card">
        <h4>OUR SOLUTION</h4>
        <p>all-in-one solution provides a comprehensive directory of NGOs, enabling users to explore missions, volunteer opportunities, and fundraising campaigns.</p>
        
        <img src="/images/aboutus.jpg" alt="imgsource" />
      </div>
    </div>
  );
}

export default Ourmission;
