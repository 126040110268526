import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import '../Styles/register.css';
import axios from 'axios';
import axiosInstance from './axiosSetup';
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isUserAuthenticated = () => {
    const accessToken = localStorage.getItem('access_token');
    return !!accessToken; // Returns true if the token exists, false otherwise
  };

  const onSubmit = async (data) => {
    if (!isUserAuthenticated()) {
      setErrorMessage('You need to login to submit the application.');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', data.image[0]);
      formData.append('upload_preset', 'ngo-connect');

      const cloudinaryResponse = await axios.post(
        'https://api.cloudinary.com/v1_1/dmrtbhnzu/image/upload',
        formData
      );

      const imageUrl = cloudinaryResponse.data.secure_url;
      const accessToken = localStorage.getItem('access_token');

      const response = await axiosInstance.post(
        '/ngoconnect/addngo',
        {
          ...data,
          image_url: imageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSuccessMessage(response.data.message);
      setErrorMessage('');
      setLoading(false); 
      navigate('/success-application');
      successMessage("")

    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage('');
      } else {
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <div className="ngo-registration-form">
      {loading && <div className="reg-loader"></div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input placeholder="NGO name" {...register('ngo_name', { required: true })} />
        {errors.ngo_name && <span className="error-message">NGO name is required</span>}
        
        <input placeholder='NGO-email' type="email" {...register('email', { required: true })} />
        {errors.email && <span className="error-message">Valid email is required</span>}

        <textarea className="column-span-2" placeholder='Description' {...register('description', { required: true })} />
        {errors.description && <span className="error-message">Description is required</span>}

        <input placeholder='category' {...register('category', { required: true })} />
        {errors.category && <span className="error-message">Category is required</span>}
    
        <input placeholder='Location' {...register('Location', { required: true })} />
        {errors.Location && <span className="error-message">Location is required</span>}
        
        <input placeholder ='latitude' type="number" step="any" {...register('latitude', { required: true })} />
        {errors.latitude && <span className="error-message">Valid latitude is required</span>}

        <input placeholder='longitude' type="number" step="any" {...register('longitude', { required: true })} />
        {errors.longitude && <span className="error-message">Valid longitude is required</span>}

        <input className="column-span-2" placeholder="Website url" type="url" {...register('website_url', { required: true })} />
        {errors.website_url && <span className="error-message">Valid website URL is required</span>}

        <input
          className="column-span-2"
          type="file"
          accept="image/*"
          onChange={(e) => setValue('image', e.target.files)}
        />
        {errors.image && <span className="error-message">Image is required</span>}
        
        <button className= "reg-sucess-button" type="submit">Submit</button>
      </form>
    </div>
  );
};

export default RegisterForm;
