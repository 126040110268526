import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosSetup'; // Import the axiosInstance

const JobsForNgo = ({ ngoId }) => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          // Access token not found, handle accordingly
          return;
        }

        const response = await axiosInstance.get(`/ngoconnect/get-jobs/${ngoId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setJobs(response.data['Jobs for NGO']);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, [ngoId]);

  return (
    <div>
      {jobs.length > 0 ? (
        <ul>
          {jobs.map((job) => (
            <li key={job.id}>
              <h4>{job.position_title}</h4>
              <p>{job.description}</p>
              <p>Qualifications: {job.qualifications}</p>
              <a href={job.application_link} target="_blank" rel="noopener noreferrer">
                Apply Now
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <p>No open positions currently available.</p>
      )}
    </div>
  );
};

export default JobsForNgo;
