import React from "react";
import AnimatePage from "../AnimatePage";

function PrivacyPolicy() {
  return (
    <AnimatePage>

  
    <div style={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
      <h2>Privacy Policy</h2>

      <p>
        <strong>Last Updated:</strong> 2/1/2024
      </p>

      <section>
        <h3>1. Introduction</h3>
        <p>
          Welcome to NGO-connect. This Privacy
          Policy explains how we collect, use, and protect your personal
          information when you use our website, services, or interact with us.
        </p>

        <p>
          By using our website, you agree to the terms outlined in this Privacy
          Policy.
        </p>
      </section>

      <section>
        <h3>2. Information We Collect</h3>

        <h4>2.1 Personal Information</h4>
        <p>
          <strong>For Users:</strong> [List of personal information collected
          from users, e.g., name, email, contact information, etc.]
        </p>

        <p>
          <strong>For NGOs:</strong> [List of personal information collected
          from NGOs, e.g., organization name, contact person's name, email,
          address, etc.]
        </p>

        <h4>2.2 Non-Personal Information</h4>
        <p>
          [List of non-personal information collected, e.g., browser type, IP
          address, etc.]
        </p>
      </section>

      <section>
        <h3>3. How We Use Your Information</h3>

        <h4>3.1 Users</h4>
        <p>
          We may use the personal information collected from users for the
          following purposes:
        </p>
        <ul>
          <li>To provide and improve our services.</li>
          <li>To communicate with users.</li>
          <li>To send promotional materials.</li>
        </ul>

        <h4>3.2 NGOs</h4>
        <p>
          We may use the personal information collected from NGOs for the
          following purposes:
        </p>
        <ul>
          <li>To verify and manage NGO profiles.</li>
          <li>To communicate with NGOs.</li>
          <li>
            To send updates about volunteer positions, donations, or other
            relevant opportunities.
          </li>
        </ul>
      </section>

      <section>
        <h3>4. Sharing Your Information</h3>
        <p>
          We may share your personal information with third parties for the
          following purposes:
        </p>
        <ul>
          <li>With NGOs for advertising purposes.</li>
          <li>
            With service providers assisting us in delivering our services.
          </li>
          <li>With legal authorities if required by law.</li>
        </ul>
      </section>

      <section>
        <h3>5. Cookies and Tracking Technologies</h3>
        <p>
          We may use cookies and similar technologies to collect non-personal
          information about your activities on our website.
        </p>
      </section>

      <section>
        <h3>6. Data Security</h3>
        <p>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, and
          destruction.
        </p>
      </section>

      <section>
        <h3>7. Your Choices</h3>
        <p>
          Users and NGOs have the right to:
        </p>
        <ul>
          <li>Access, correct, or delete their personal information.</li>
          <li>Opt-out of receiving promotional emails.</li>
        </ul>
      </section>

      <section>
        <h3>8. Changes to this Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. The most recent
          version will be posted on our website.
        </p>
      </section>

      <section>
        <h3>9. Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us at [Your Contact Information].
        </p>
      </section>
      <section>
        <h3>10. Advertisements</h3>
        <p>
            Our website may display one or two advertisements to support the
            maintenance and operation of our services. These advertisements may be
            provided by third-party advertising partners.
        </p>
        <p>
            Third-party advertisers may use cookies and similar technologies to
            collect information about your activities on our website and other
            websites to provide you with targeted advertisements based on your
            interests.
        </p>
        <p>
            Please note that we do not control the content or links that appear in
            these advertisements, and these third-party advertisers may have their
            own privacy policies.
        </p>
    </section>

    </div>
    </AnimatePage>
  );
}

export default PrivacyPolicy;
