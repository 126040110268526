import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from "../Components/axiosSetup";
import '../Styles/SuperAdmin.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse,faUser, faEnvelope,faBuildingNgo,faChevronDown} from '@fortawesome/free-solid-svg-icons';

import Applications from "../Components/Approveapplications";
import ManageListing from "../Components/Managelist";
import UserList from "../Components/Userlist";
import SuperAdminhome from "../Components/SuperAdminhome";

function Admin() {
  const navigate = useNavigate();
  const [selectedLink, setSelectedLink] = useState(() => {
    return localStorage.getItem('selectedLink') || 'home';
  });
  const [fullName, setFullName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');

    if (!accessToken) {
      navigate('/user-accounts');
      return;
    }

    const cachedAuthenticationStatus = localStorage.getItem('is_authenticated');
    if (cachedAuthenticationStatus === 'true') {
      fetchData();
    } else {
      fetchData();
    }
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem('selectedLink', selectedLink);
  }, [selectedLink]);

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');

      const response = await axiosInstance.get("/ngoconnect/checklogin", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setFullName(response.data.fullName);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('is_authenticated');
    navigate('/');
  };

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="admin-container">
      {/* Sidebar */}
      <div className="ngo-super-admin-sidebar" style={{ position: 'fixed', width: '200px' }}>
        <div className="ndo-sidebar-content">
          <div className='logo-container'>
            <img src="/images/favicon.png" alt="ngoconnect logo" />
          </div>
          <ul>
            <li
              onClick={() => handleLinkClick("home")}
              className={selectedLink === "home" ? "active" : ""}
            >
             <FontAwesomeIcon icon={faHouse} size="1.5x" style={{ color: '#d87927' }} /> Home
            </li>
            <li
              onClick={() => handleLinkClick("applications")}
              className={selectedLink === "applications" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faEnvelope} size="1.5x" style={{ color: '#d87927' }} /> Applications
            </li>
            <li
              onClick={() => handleLinkClick("all-ngos")}
              className={selectedLink === "all-ngos" ? "active" : ""}
            >
             <FontAwesomeIcon icon={faBuildingNgo} size="1.5x" style={{ color: '#d87927' }} />  All NGOs
            </li>
            <li
              onClick={() => handleLinkClick("user-list")}
              className={selectedLink === "user-list" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faUser} size="1.5x" style={{ color: '#d87927' }} /> User List
            </li>
          </ul>
        </div>
        <div className="ngo-connect-copyright">
          <p>@ngoc0nnect 2024</p>

        </div>
      </div>

      {/* Main Content */}
      <div className="ngo-admin-main-content" style={{ marginLeft: '250px' }}>
        <div className="ngo-dashbord-header">
          <h6>hello Admin</h6>

          <div className="dropdown-trigger" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <div className="ngo-user-profile">
                <div className="profile-initial">
                  {fullName && fullName.charAt(0).toUpperCase()} {/* Display the first letter of fullName */}
                </div>
                    <p>{fullName} <FontAwesomeIcon icon={faChevronDown} /> </p>
              </div>
            
            {showDropdown && (
              <div className="dropdown-content">
                <ul>
                <li><Link to="/">Main Home Page</Link></li>
                  <li onClick={handleLogout}>Logout</li>
                  
                </ul>
              </div>
            )}
          </div>
        </div>

        {selectedLink === "home" && <SuperAdminhome />}
        {selectedLink === "applications" && <Applications />}
        {selectedLink === "all-ngos" && <ManageListing />}
        {selectedLink === "user-list" && <UserList />}
      </div>
    </div>
  );
}

export default Admin;
