import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios'; // Import Axios library for making HTTP requests
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const GoogleSignUp = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [error, setError] = useState(null); // State to hold error message

  const postUser = (fullname, email) => {
    // Define your endpoint URLs
    const addUserEndpoint = '/ngoconnect/addusers'; // Replace with your AddUser endpoint URL
    
    // Prepare the user data object
    const userData = {
      fullname: fullname,
      email: email, // Use the email from credential response
      password: 'x!T3Kv#r9L@wZ6%' // Set the password to default
      // You can set other default values for user data if needed
    };

    // Make a POST request to the AddUser endpoint to add the user
    axios.post(addUserEndpoint, userData)
      .then(response => {
        console.log('User added successfully:', response.data);

        // If the user is successfully added, proceed to login
        // Redirect the user to the home page ("/") after successful login
        loginUser(email, 'x!T3Kv#r9L@wZ6%'); // Call loginUser with the added user's email and default password
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          setError('User with this email already exists');
        } else {
          console.error('Failed to add user:', error);
        }
      });
  };

  const loginUser = (email, password) => {
    // Define your UserLogin endpoint URL
    const userLoginEndpoint = '/ngoconnect/login'; // Replace with your UserLogin endpoint URL

    // Prepare the login data object
    const loginData = {
      email: email,
      password: password
    };

    // Make a POST request to the UserLogin endpoint to log in the user
    axios.post(userLoginEndpoint, loginData)
      .then(response => {
        console.log('User logged in successfully:', response.data);

        const userData = response.data;

        // Store the tokens in local storage
        localStorage.setItem('refresh_token', userData.refresh_token);
        localStorage.setItem('access_token', userData.access_token);
        localStorage.setItem('user_role', userData.role);
        

        // Redirect the user to the home page ("/") after successful login
        navigate("/");
      })
      .catch(error => {
        console.error('Failed to log in user:', error);
      });
  };

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <GoogleLogin
        onSuccess={credentialResponse => {
          const credentialResponseDecode = jwtDecode(credentialResponse.credential);

          // Extract the fullname and email from the decoded response
          const fullname = credentialResponseDecode.name;
          const email = credentialResponseDecode.email;

          // Post the user data to your endpoint with fullname, email, and default password
          postUser(fullname, email);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </div>
  );
};

export default GoogleSignUp;
