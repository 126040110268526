import React from "react";
import '../Styles/SuperAdmin.css';


import NgoCount from "./Statisctics/Ngocount";
import ApplicationCount from "./Statisctics/ApplicationCount";
import UsersCount from "./Statisctics/Usercount";

function SuperAdminhome() {
    return(
        <div className="ngo-superadmin-home">
            <h1>Dashbord</h1>

            <div className="ngo-home-statistics">
                <div className="ngo-home-stats">
                    <p>Applications</p>
                    <h1><ApplicationCount /></h1>
                </div>

                <div className="ngo-home-stats">
                    <p>All NGOs</p>
                    <h1><NgoCount /></h1>

                </div>

                <div className="ngo-home-stats">
                    <p>Active Users</p>
                    <h1><UsersCount /></h1>

                </div>

            </div>

            <div className="ngo-superadmin-new">
                
                <div className="ngo-homeadmin-views">
                  {/* Add content for blank area */}
                  <h1>Add content</h1>
                </div>

                <div className="ngo-homeadmin-views">
                  {/* Add content for blank area */}
                  <h1>Add content</h1>
                </div>

            </div>
        </div>
    );
}

export default SuperAdminhome