import React from "react";
import AnimatePage from "../AnimatePage";

function TermsOfService() {
  return (
    <AnimatePage>
    <div style={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
      <h2>Terms of Service</h2>

      <p>
        Welcome to NGO-connect. These Terms of
        Service govern your use of our website, services, and interactions with
        us.
      </p>

      <section>
        <h3>1. Acceptance of Terms</h3>
        <p>
          By using our website, you agree to comply with and be bound by these
          Terms of Service. If you disagree with any part of these terms, please
          do not use our services.
        </p>
      </section>

      <section>
        <h3>2. Use of Our Services</h3>
        <p>
          You agree to use our services for lawful purposes and in a way that
          does not violate the rights of others or inhibit their use of the
          services.
        </p>
      </section>

      <section>
        <h3>3. User Accounts</h3>
        <p>
          To access certain features of our services, you may be required to
          create a user account. You are responsible for maintaining the
          confidentiality of your account information and agree to accept
          responsibility for all activities that occur under your account.
        </p>
      </section>

      <section>
        <h3>4. Privacy Policy</h3>
        <p>
          Your use of our services is also governed by our Privacy Policy. Please
          review the Privacy Policy to understand our practices.
        </p>
      </section>

      <section>
        <h3>5. Content and Intellectual Property</h3>
        <p>
          All content on our website, including text, graphics, logos, images,
          and software, is the property of [Your Website] and is protected by
          intellectual property laws.
        </p>
      </section>

      <section>
        <h3>6. Limitation of Liability</h3>
        <p>
          [Your Website] is not liable for any direct, indirect, incidental,
          consequential, or exemplary damages arising from your use of our
          services or any third-party services linked to our website.
        </p>
      </section>

      <section>
        <h3>7. Changes to Terms of Service</h3>
        <p>
          [Your Website] reserves the right to update or modify these Terms of
          Service at any time. The most recent version will be posted on our
          website.
        </p>
      </section>

      <section>
        <h3>8. Contact Us</h3>
        <p>
          If you have any questions about these Terms of Service, please contact
          us at [Your Contact Information].
        </p>
      </section>
    </div>
    </AnimatePage>
  );
}

export default TermsOfService;
