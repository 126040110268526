import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosSetup";

const NGOJobs = ({ ngoId }) => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axiosInstance.get(`/ngoconnect/fetch-jobs/${ngoId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        setJobs(response.data.jobs);
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        setError(errorMessage);
      }
    };

    fetchJobs();
  }, [ngoId]);

  const handleDescriptionClick = (description) => {
    setSelectedJob(description);
  };

  const handleDeleteClick = async (jobId) => {
    try {
      await axiosInstance.delete(`/ngoconnect/jobs/${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const response = await axiosInstance.get(`/ngoconnect/fetch-jobs/${ngoId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setJobs(response.data.jobs);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(errorMessage);
    }
  };

  return (
    <div>
      <h2>NGO Jobs</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <table>
        <thead>
          <tr>
            <th>Position Title</th>
            <th>Application Link</th>
            <th>Position Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr key={job.id}>
              <td>{job.position_title}</td>
              <td>{job.application_link}</td>
              <td>{job.position_type}</td>
              <td>
                <button onClick={() => handleDescriptionClick(job.description)}>
                  View Description
                </button>
                <button onClick={() => handleDeleteClick(job.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedJob && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setSelectedJob(null)}>
              &times;
            </span>
            <p>{selectedJob}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NGOJobs;
