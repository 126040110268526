import React from "react";
import '../Styles/sucsess.css'
import { Link } from 'react-router-dom';

function ApllicationMessage() {
    return (
        <div className="success-container">
            <div className="success-txt">
                <div>
                    <h1>
                    Bravo!<br>
                        </br>
                        Application Submited
                    </h1>
                    <p>
                    Your application has gracefully landed in our hands 
                    and is now basking in the limelight of our thorough review process. Anticipate the grand reveal as we unveil the 
                    verdict—approved or rejected. Stay tuned for the official scoop! 🎉
                    </p>
                    <div className="success-navigation">
                    <Link to='/'>HOME | </Link>
                    <Link to ="/ngo-listings">LISTINGS</Link>

                    </div>
                    

                </div>
              

            </div>

            <div className="Success-image">
                <img  src="/images/success.gif" alt="suceess-application"/>


            </div>

        </div>
    );
};

export default ApllicationMessage;