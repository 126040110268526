// CookieConsentBanner.js
import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="cookieConsent"
            style={{
                background: "#fff",
                color: "#000",
                borderTop: "1px solid #D3D3D3"
            }}
            buttonStyle={{
                color: "#fff",
                background: "#d87927",
                fontSize: "14px",
                borderRadius: "3px"
            }}
            expires={365}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
};

export default CookieConsentBanner;
