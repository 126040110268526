import React from "react";
import { Link } from "react-router-dom";
import SignUp from "../Components/Signup";
import GoogleSignUp from "../Components/GoogleAouth/Googlelogin";
import { Helmet } from "react-helmet";



function Usersignup(){
    const featuredImage = '/images/favicon.png'
    return(

        <div className="user-page-container">
          <Helmet>
            <title>NGO-CONNECT</title>
            <meta name="description" content="A user-friendly platform that connects user with a comprehensive list of NGOs" />
            <meta property="og:title" content="NGO-CONNECT" />
            <meta property="og:description" content="A user-friendly platform that connects user with a comprehensive list of NGOs" />
            <meta property="og:image" content={featuredImage} />
          </Helmet>

            <div className="ngo-login-form">
            <img src="/images/favicon.png" alt="ngoconnect-login-logo" />
                <SignUp />
                <GoogleSignUp />
                <div>
                <p > I already have an account <span> <Link className="span-to" to="/login"> LOGIN </Link></span></p>
                
              </div>
              <p className="tos-signuppage"> By signinging up you agree to the <span><Link className="span-to" to ="/terms-of-service">Terms and conditions</Link></span></p>
              
            </div>

            <div className="ngo-login-sidesection">
                <div>
                <img src="/images/useraccount.jpg" alt="ngo connect" />
                </div>
                
            </div>
            
        </div>
        
    )
}

export default Usersignup