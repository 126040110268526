import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosSetup';

const NgoCount = () => {
  const [ngoCount, setNgoCount] = useState(null);

  useEffect(() => {
    const fetchNgoCount = async () => {
      try {
        const response = await axiosInstance.get('/ngoconnect/ngonumbers'); // Replace with your actual API endpoint
        setNgoCount(response.data.ngo_count);
      } catch (error) {
        console.error('Error fetching NGO count:', error);
      }
    };

    fetchNgoCount();
  }, []);

  return (
    <div>
     
      {ngoCount !== null ? (
        <p>{ngoCount}</p>
      ) : (
        <p>loading..</p>
      )}
    </div>
  );
};

export default NgoCount;
