import React from "react";
import { Link } from "react-router-dom";
import '../Styles/Landingpage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';



function Footer(){

    
    return(
        <div className="ngo-footer-area">
            <div>
               <img src="/images/footerimage.png" alt="footer " />
            </div>
            <div className="ngo-footer">
                <div className="ngo-footer-left">
                    <h1>
                        NGO.<span>connect</span>
                    </h1>
                    <p>Be the change that you wish to see in the world.</p>
                    <div className="call-to-actions">
                        <button>
                            Sign up
                        </button>
                        
                        <button>
                            Register NGO
                        </button>

                    </div>
                
                </div>

                <div className="ngo-footer-contacts">
                   
                    <div className="ngo-contact-details">
                    <h1>For Queries</h1>
                        <p>Call : 0798306373</p>
                        <p>Email : ngoconnection639@gmail.com </p>

                        <div className="ngo-footer-social-icons"> 
                            <FontAwesomeIcon className="icon" icon={faFacebook}  style={{ color: '#213343' }} />
                            <FontAwesomeIcon className="icon" icon={faInstagram}  style={{ color: '#213343' }} />
                        </div>

                        <div className="footer-links">
                            <Link to="/privacy-policy">
                                <p>Privacy Policy |</p> 
                            </Link>

                            <Link to="/terms-of-service">
                                <p>Terms of service</p>
                            </Link>

                        </div>

                    </div>
                   


                
                </div>

            </div>

            
        </div>
    );
}

export default Footer;