import React, { useState, useEffect, Suspense } from "react";

import { Link } from "react-router-dom";
import axiosInstance from "./axiosSetup"; // Import the axiosInstance
import '../Styles/ngolist.css';
import AnimatePage from "./AnimatePage";

function NgoListings() {
  const [ngoList, setNgoList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNgoList, setFilteredNgoList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [loading, setLoading] = useState(false); // State to track loading state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching data
      try {
        const response = await axiosInstance.get("/ngoconnect/ngolist");

        setNgoList(response.data.All_NGOs);
        setFilteredNgoList(response.data.All_NGOs);
      } catch (error) {
        console.error("Error fetching NGO data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData();
  }, []);

  const truncateDescription = (description) => {
    return description.length > 150 ? description.substring(0, 150) + '...' : description;
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredNgoList = ngoList.filter((ngo) =>
      ngo.ngo_name.toLowerCase().includes(searchTerm) ||
      ngo.description.toLowerCase().includes(searchTerm) ||
      ngo.focus_areas.toLowerCase().includes(searchTerm) ||
      ngo.Location.toLowerCase().includes(searchTerm)
    );

    setFilteredNgoList(filteredNgoList);
  };

  const handleKnowMoreClick = async (ngoId) => {
    try {
      const response = await axiosInstance.put(`/ngoconnect/ngo/${ngoId}/visit`);
      console.log(response.data); // Optionally handle the response
    } catch (error) {
      console.error("Error updating visit count:", error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNgoList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <AnimatePage>
      <div>
        <form>
          <input
            className="ngo-search-bar"
            type="text"
            placeholder="Search NGOs..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </form>

        <Suspense fallback={<div>Loading...</div>}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {currentItems.length > 0 ? (
                currentItems.map((ngo) => (
                  <div key={ngo.id} className="ngo-list-card">
                    <div className="ngo-image">
                      <img src={ngo.image_url} alt={ngo.ngo_name} />
                    </div>

                    <div className="ngo-details">
                      <h3>{ngo.ngo_name}</h3>

                      <div className="ngo-listing-Highlights"> 
                        <p> Focus Area: <span>{ngo.focus_areas} </span> |</p>
                        <p> Location:  <span>{ngo.Location}</span></p>
                      </div>

                      <p>{truncateDescription(ngo.description)}</p>
                      <Link
                        to={`/ngo/${ngo.id}`}
                        className="ngo-know-more"
                        onClick={() => handleKnowMoreClick(ngo.id)}
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <p>No NGOs found.</p>
              )}
            </>
          )}
        </Suspense>

        <div className="pagination">
          <p>EXPLORE MORE NGOS</p>
          {Array.from({ length: Math.ceil(filteredNgoList.length / itemsPerPage) }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </AnimatePage>
  );
}

export default NgoListings;
