import React, { useState, useEffect } from "react";
import axiosInstance from "../Components/axiosSetup";
import { useNavigate, Link } from "react-router-dom";

import CreateJobForm from "../Components/NGOadmin/Jobpost";
import UpdateNgoForm from "../Components/NGOadmin/UpdateNgo";
import ImageUploadForm from "../Components/NGOadmin/UpdateImage";
import NgoAdminHome from "../Components/NGOadmin/NgoAdminHome";
import NGOJobs from "../Components/NGOadmin/JobList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPencilAlt, faPlus, faChevronDown } from "@fortawesome/free-solid-svg-icons"; // Added icon for the new link
import "../Styles/NgoAdmin.css"; // Import styles

function NgoAdmin() {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLink, setSelectedLink] = useState(() => {
    return localStorage.getItem("selectedLink") || "home";
  });
  const [ngoDetails, setNgoDetails] = useState({ id: "" });
  const [error, setError] = useState(null);
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axiosInstance.get("/ngoconnect/my-ngo", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setNgoDetails(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchUserData = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');

        const response = await axiosInstance.get("/ngoconnect/checklogin", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setFullName(response.data.fullName);
        console.log(fullName)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
    fetchUserData();
  }, [fullName]);

  useEffect(() => {
    localStorage.setItem("selectedLink", selectedLink);
  }, [selectedLink]);

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('is_authenticated');
    navigate('/');
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="ngo-admin-container">
      {/* Sidebar */}
      <div className="ngo-admin-sidebar">
        <div className="sidebar-content">
          <div className="logo-container">
            <img src="/images/favicon.png" alt="ngoconnect logo" />
          </div>
          <ul>
            <li
              onClick={() => handleLinkClick("home")}
              className={selectedLink === "home" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faHome} size="1.5x" style={{ color: "#d87927" }} />
              Home
            </li>
            <li
              onClick={() => handleLinkClick("update-ngo")}
              className={selectedLink === "update-ngo" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faPencilAlt} size="1.5x" style={{ color: "#d87927" }} />
              Update NGO
            </li>
            {/* New link for Create Job */}
            <li
              onClick={() => handleLinkClick("create-job")}
              className={selectedLink === "create-job" ? "active" : ""}
            >
              <FontAwesomeIcon icon={faPlus} size="1.5x" style={{ color: "#d87927" }} />
              Create Job
            </li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className="ngo-admin-main-content">
        <div className="header-ngo-admin">
          <h1>Dashboard</h1>

          <div className="dropdown-trigger" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <div className="ngo-user-profile">
              <div className="profile-initial">
                {fullName && fullName.charAt(0).toUpperCase()} {/* Display the first letter of fullName */}
              </div>
              <p>{fullName} <FontAwesomeIcon icon={faChevronDown} /> </p>
            </div>

            {showDropdown && (
              <div className="dropdown-content">
                <ul>
                  <li><Link to="/">Main Home Page</Link></li>
                  <li onClick={handleLogout}>Logout</li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {selectedLink === "home" && (
          <div>
            {error && <p>Error: {error.message}</p>}

            <div>
              <p>ID: {ngoDetails.id}</p>
            </div>
            <NgoAdminHome />
          </div>
        )}

        {selectedLink === "update-ngo" && (
          <div>
            <UpdateNgoForm />
            <ImageUploadForm />
          </div>
        )}

        {/* Display CreateJobForm when the selected link is "create-job" */}
        {selectedLink === "create-job" && <CreateJobForm />}

        {/* Display NGOJobs when the selected link is "create-job" and ngoDetails.id is truthy */}
        {selectedLink === "create-job" && ngoDetails.id && <NGOJobs ngoId={ngoDetails.id} />}
      </div>
    </div>
  );
}

export default NgoAdmin;
