
import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosSetup";
import AverageRating from "./AvarageRating";
import NgoReviews from "./NGOAdminreview";
import "./ngoadminboard.css"
import MyCalendar from "./calender";
import VisitCount from "./VisitCount";


function NgoAdminHome (){
    const [ngoDetails, setNgoDetails] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
          try {
            const accessToken = localStorage.getItem("access_token");
            const response = await axiosInstance.get("/ngoconnect/my-ngo", {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
    
            setNgoDetails(response.data);
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchData();
      }, []);

    return (
        <div className="ngo-admin-home">
            <div className="ngo-home-top">
                  {error && <p>Error: {error}</p>}
              {ngoDetails && (
                <div className="ngo-admin-details">
                  <div className="ngo-admin-text-side">
                   <h2> My NGO Details ({ngoDetails.id})</h2>
                    <h5>Name:</h5>
                    <p>{ngoDetails.ngo_name}</p>
                    <h5>Email:</h5>
                    <p>{ngoDetails.email}</p>
                    <h5>Location:</h5>
                    <p>{ngoDetails.Location}</p>
                    </div>
                  <div className="ngo-admin-image">
                    <img src="/images/sidengo.jpg" alt="side-ngo" />
                    

                  </div>
                  
                </div>
              )}
              <div>
                <h1>Performance</h1>
              {ngoDetails && <AverageRating ngoId={ngoDetails.id} />}
              {ngoDetails && <VisitCount ngoId={ngoDetails.id} />}


              </div>
              <div>
                <h1>
                  Shortcuts
                </h1>
                <ul>
                  <li>Main Home </li>
                  <li> View Listings</li>
                </ul>
              </div>

            </div>
            
            <div className="ngo-admin-home-bottom">
              <div className="ngo-home-revieslist">
                {ngoDetails && <NgoReviews ngoId={ngoDetails.id} />}
              </div>
              <div>
                <MyCalendar />

              </div>


            </div>
                    
          
  

        </div>
    );
}

export default NgoAdminHome