import React from "react";
import '../Styles/useraccounts.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


import SignIn from "../Components/Signin";
import GoogleLoginComponent from "../Components/GoogleAouth/Googlesignup";


function Usersignin(){
  const featuredImage = '/images/favicon.png'
    return(
      
        <div className="user-page-container">
          <Helmet>
            <title>NGO-CONNECT</title>
            <meta name="description" content="A user-friendly platform that connects user with a comprehensive list of NGOs" />
            <meta property="og:title" content="NGO-CONNECT" />
            <meta property="og:description" content="A user-friendly platform that connects user with a comprehensive list of NGOs" />
            <meta property="og:image" content={featuredImage} />
          </Helmet>

            <div className="ngo-login-form">
            <img src="/images/favicon.png" alt="ngoconnect-login-logo" />
              <SignIn />
              <GoogleLoginComponent />
              <div>
                <p > Dont have an account <span> <Link className="span-to" to="/signup"> SIGN UP</Link></span></p>
              </div>
             
            </div>

            <div className="ngo-login-sidesection">
                <div>
                <img src="/images/useraccount.jpg" alt="ngo connect" />
                </div>
                
            </div>
            
        </div>
    )
}

export default Usersignin