// ProtectedRoute.js
import React from 'react';
import {  Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, allowedRoles }) => {
  // Check if the user's role is allowed
  const userRole = localStorage.getItem('user_role');
  const isAllowed = allowedRoles.includes(userRole);

  return isAllowed ? element : <Navigate to="/unauthorized" replace />;
};

export default ProtectedRoute;



