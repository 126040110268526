import React from 'react';
import "../Styles/unotheriseduser.css"
import { Link } from 'react-router-dom';
import AnimatePage from './AnimatePage';

const PageNotFound = () => {
  return (
    <AnimatePage >

 
    <div className='ngo-unothorised-user'>
      <div className='ngo-block-content'>
        <div>
          <h3>Error</h3>
          <h1>404</h1>
        </div>
        <h2>Page Not Found</h2>
        <p>Looks like you've stumbled upon uncharted territory.But don't worry, our engineers are working hard to find it! Try these for now</p>
        <Link to="/" className='ngo-nav'> Home</Link>  | <Link className='ngo-nav' to="/ngo-listings"> Listings</Link> | <Link className='ngo-nav' to="/about-us"> About Us</Link>

      </div>
      
    </div>
    </AnimatePage>
  );
};

export default PageNotFound;