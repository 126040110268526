import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Styles/useraccounts.css';

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('/ngoconnect/login', {
        email: formData.email,
        password: formData.password,
      });
    
      const userData = response.data;
    
      // Log the user data to the console to check the role
      console.log('User Data:', userData);
    
      // Store the access token and role in localStorage
      localStorage.setItem('refresh_token', userData.refresh_token);
      localStorage.setItem('access_token', userData.access_token);
      localStorage.setItem('user_role', userData.role);
    
      // Reset form data and error message
      setFormData({
        email: '',
        password: '',
      });
      setErrorMessage(null);
    
      // Log a message to the console indicating successful login
      console.log('Login successful. Access Token:', userData.access_token);
    
      //Redirect based on user role
      switch (userData.role) {
        case 'super_admin':
          navigate('/super-admin');
          break;
        case 'admin':
          navigate('/ngo-admin');
          break;
        case 'normal':
          navigate('/ngo-listings');
          break;
        default:
          navigate('/');
      }
    
      // TODO: Redirect to the home page or perform any other necessary actions upon successful login
    } catch (error) {
      // Handle login failure
      setErrorMessage('Invalid email or password. Please try again.');
      console.error('Login failed:', error.response.data);
    }
  };
  

  return (
    <div className="form-container-sign-in-container">
      <form onSubmit={handleSignIn} className='ngo-user-form'>
        <h1>Login</h1>
        
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit">Sign In</button>
        <div className='opt'>
          <p>-------- or -------- </p>

        </div>
      </form>
    </div>
  );
};

export default SignIn;
